<template>
  <div class="new-message">
    <div class="new-message__top d-flex align-center">
      <v-icon @click="$router.push('/messages')">mdi-arrow-left</v-icon>
      <v-text-field
        flat
        solo
        hide-details
        label="Send new message"
        type="text"
        append-icon="mdi-magnify"
        append-outer-icon="mdi-phone"
        v-model="newMessage"
      ></v-text-field>
      <ActionsMenu class="ml-4" />
    </div>
    <div class="new-message__body">
      <v-list>
        <v-list-item>
          <v-list-item-avatar color="#27AE60">
            <v-icon color="#fff">mdi-account</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <p>
              Use the code (582520) on WeChat to log in to your account. Don't
              forward the code!
            </p>
            <p>
              WeChat verification code (469695) may only be used once to verify
              mobile number. For account safety, don't forward the code to
              others.
            </p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
    <v-form class="new-message__form w-100">
      <div class="d-flex align-center flex-row">
        <div class="">
          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-image-area</v-icon>
            </template>
            <v-card class="overflow-hidden">
              <v-card-text class="new-message__file">
                <v-tabs v-model="model">
                  <v-tab class="tab-item">You photos</v-tab>
                  <v-tab class="tab-item">Photo album</v-tab>
                  <v-tab class="tab-item">Upload photos</v-tab>
                </v-tabs>
                <div class="fileUploadStyle">
                  <v-btn
                    color="primary"
                    :loading="isSelecting"
                    @click="onButtonClick"
                  >
                    {{ defaultButtonText }}
                  </v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    accept="image/*"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
        <v-text-field
          flat
          solo
          hide-details
          label="Type to message"
          type="text"
          :append-icon="icon"
          append-outer-icon="mdi-send-outline"
          v-model="message"
          @click:append-outer="dialog = true"
          @click:append="changeIcon"
        ></v-text-field>
      </div>
    </v-form>
  </div>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu.vue'

export default {
  name: 'NewMessages',
  components: {
    ActionsMenu
  },
  data: () => ({
    model: 0,
    defaultButtonText: 'Select photos from your computer',
    isSelecting: false,
    dialog: false,
    message: '',
    newMessage: '',
    iconIndex: 0,
    icons: [
      'mdi-emoticon',
      'mdi-emoticon-cool',
      'mdi-emoticon-dead',
      'mdi-emoticon-excited',
      'mdi-emoticon-happy',
      'mdi-emoticon-neutral',
      'mdi-emoticon-sad',
      'mdi-emoticon-tongue'
    ]
  }),
  computed: {
    icon() {
      return this.icons[this.iconIndex]
    }
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true
      window.addEventListener(
        'focus',
        () => {
          this.isSelecting = false
        },
        { once: true }
      )
      console.log(this.$refs)

      this.$refs.uploader.click()
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0]
    },
    sendMessage() {
      this.clearMessage()
    },
    changeIcon() {
      this.iconIndex === this.icons.length - 1
        ? (this.iconIndex = 0)
        : this.iconIndex++
    }
  }
}
</script>
